import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { GlobalService } from "@app/shared/services/global/global.service";
import { EmployeeResponse } from "./employeeList.modal";

@Injectable({
  providedIn: "root",
})
export class EmployeeListService {
  baseIp = environment.baseIp;
  apiPrefix = environment.apiPrefix;
  accessToken = this.globalService.getAccessTokenFromCookie();
  companyId = this.globalService.getCompanyIdFromStorage();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {}

  getEmployeesList(): Observable<EmployeeResponse> {
    const params = new HttpParams().set('company_id', this.companyId);
    return this.http.get<EmployeeResponse>(
      `${this.baseIp}${this.apiPrefix}userstaff`,
      { params }
    );
  }
}
