import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { environment } from "@env/environment";
import { LoginService } from "@app/modules/auth/login/services/login.service";
import { HelperService } from "@app/shared/services/helper/helper.service";
import { LayoutService } from "../../services/layout.service";
import { SettingService } from "@app/modules/setting/general-setting/services/setting.service";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { GlobalService } from "@app/shared/services/global/global.service";
import { AttendanceService } from "@app/modules/daybook-management/attendance/services/attendance.service";
import { ToastrMessageService } from "./../../../../shared/services/toastr-message/toastr-message.service";
import { DatePipe } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { NotificationService } from "@app/modules/notification/services/notification.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  todaysDateInEnglish = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  defaultImagePath = environment.defaultImagePath;
  imageUrl = environment.baseImageUrl;
  selectedLanguage: string;
  user_id = this.localStorageService.getLocalStorageItem("staff_id");
  staff_id = this.localStorageService.getLocalStorageItem("user_id");
  companyId = this.globalService.getCompanyIdFromStorage();
  attendanceMessageForm: FormGroup;
  attendanceHistory = [];
  checkInTime:any;
  checkInTime2:any;
  buttonStatus: any;
  ellapsedTime:any;
  checkin_percent:any;
  checkin_percent_second_half:any;
  break_percent:any;
  lunch_percent:any;
  onsite_percent:any;
  notificationData:any=0;
  checkin_datetime:any;
  checkout_datetime:any;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private helperService: HelperService,
    private layoutService: LayoutService,
    private generalSettingService: SettingService,
    private localStorageService: LocalStorageService,
    private attendanceService: AttendanceService,
    private toasterMessageService: ToastrMessageService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private globalService: GlobalService
  ) {
    setInterval(() => {
      if (this.buttonStatus && this.buttonStatus.checkin_time) {
        let checkin = this.buttonStatus.checkin_time;
     // let checkin = this.checkInTime;
      if(checkin){
      const caltime = this.calculateDiff(checkin);
      let seconds:any = caltime.split(':')[2];
      if(seconds<10){ 
        seconds = '0'+seconds;
      }
      let minutes:any = caltime.split(':')[1];
      if(minutes<10){ 
        minutes = '0'+minutes;
      }

      let hours:any = caltime.split(':')[0];
      if(hours<10){ 
        hours = '0'+hours;
      }
      this.buttonStatus.percentage.forEach( (element) => {
        if(element.att_status=='Check' && element.information!='final checkout of the day'){
          this.checkin_percent = element.percent;
        }
        if(element.att_status=='Lunch'){
          this.lunch_percent = element.percent;
        }
        if(element.att_status=='Break'){
          this.break_percent = element.percent;
        }
        if(element.att_status=='Onside'){
          this.onsite_percent = element.percent;
        }
        if(element.att_status=='Check' && element.information=='final checkout of the day'){
          this.checkin_percent_second_half = element.percent;
        }
      });
    // this.checkin_percent = this.buttonStatus.checkin_percent;
    // this.break_percent = this.buttonStatus.break_percent;
    // this.lunch_percent = this.buttonStatus.lunch_percent;
    // this.onsite_percent = this.buttonStatus.onsite_percent;
    // this.checkin_percent_second_half = this.buttonStatus.checkin_percent_second_half;
   
      this.ellapsedTime = `${(hours)}:${(minutes)}:${(seconds)}`;//this.calculateDiff(checkin);
    }else{
      this.ellapsedTime = '00:00:00';
    }
  
    }}, 1000);
  }

  calculateDiff(dateSent){
    let currentDate = new Date();
    
    if(this.buttonStatus.lunchout==1 || this.buttonStatus.checkin==1 || this.buttonStatus.breakout==1 || this.buttonStatus.onsiteout==1){
     // currentDate = new Date(this.checkInTime2);
      currentDate = new Date(this.buttonStatus.last_checkout_datetime);
   }
   
    if(dateSent==''){
       dateSent = new Date();
    }else{
      dateSent = new Date(dateSent);
    }
    const msBetweenDates = currentDate.getTime() - dateSent.getTime();
    
     return this.convertMsToTime(msBetweenDates);
   }
 
   convertMsToTime(milliseconds: number) {
     let seconds = Math.floor(milliseconds / 1000);
     let minutes = Math.floor(seconds / 60);
     const hours = Math.floor(minutes / 60);
   
     seconds = seconds % 60;
     minutes = minutes % 60;
   
     return `${(hours)}:${(minutes)}:${(seconds)}`;
   }

  navigateToDashboard() {
    this.router.navigate(["/dashboard/admin"]);
  }
  companyName;
  ngOnInit() {
    this.companyId = this.globalService.getCompanyIdFromStorage();
    this.getUserInfo();
    this.getCompanyLogo();
    this.getButtonStatus(this.staff_id);
    this.getAttendanceHistory(this.staff_id, this.todaysDateInEnglish);
    // console.log(this.user_id);
    this.globalService.getCompanyName.subscribe((data)=>{
      if(data){
        this.companyName = data;
      }
    })
  }

  getButtonStatus(id) {
    this.attendanceService.getButtonStatus(id,'').subscribe(
      (response) => {
        if (response) {
          this.buttonStatus = response;
        }
      },
      (error) => {
        this.toasterMessageService.showError(error);
      }
    );
  }
  attendanceFormData = {
    id: this.staff_id,
    datetime: "",
    type: "",
    company_id: this.companyId,
    checkin_message: "",
  };
  onAttendanceClick(buttonType) {
    this.attendanceFormData.datetime = this.datePipe.transform(
      new Date(),
      "yyyy-MM-dd hh:mm:ss"
    );
    this.attendanceFormData.type = buttonType;
    this.addAttendance(this.attendanceFormData, buttonType);
  }

  addAttendance(attendanceFormData, successMessage) {
    this.attendanceService
      .addAttendance(attendanceFormData, '')
      .subscribe(
        (response) => {
          if (response.status) {
            this.toasterMessageService.showSuccess(
              "You have successfully set " + successMessage + " time"
            );
            this.getAttendanceHistory(this.staff_id, this.todaysDateInEnglish);
            this.getButtonStatus(this.staff_id);
          } else {
            this.toasterMessageService.showError(response.detail);
          }
        },
        (error) => {
          this.toasterMessageService.showError(error);
        }
      );
  }
  getAttendanceHistory(id, date) {
    this.attendanceService
      .getAttendanceHistory(id, date)
      .subscribe((response) => {
        if (response.status) {
          // this.attendanceHistory = response;
          this.attendanceHistory = response.data[0];
          this.checkin_datetime = response.data.checkin_datetime;
          this.checkout_datetime=response.data.checkout_datetime;
          this.checkInTime = response.data[0].checkin_datetime;
          if(response.data[1]){
            this.checkInTime2 = response.data[1].checkin_datetime;
          }else{
            this.checkInTime2 = response.data[0].checkout_datetime;
          }
        } else {
          this.attendanceHistory = [];
        }
      });

      this.notificationService.getNotificationList().subscribe(
        response => {
          if (response) {
            this.notificationData = response.count;
            return;
          }
        }
      );
  }
 
  detail;
  getCompanyLogo(): void {
    // console.log("PSDADSAD",this.companyId)
    this.generalSettingService
      .getCompanyLogo(this.companyId)
      .subscribe((response) => {
        this.detail = response;
      });
  }
  isPinLogin:boolean;
  userInfo: any;
  getUserInfo() {
    this.userInfo = this.loginService.getUserInfoFromStorage();
    this.getUserFullName();
    this.isPinLogin = this.localStorageService.getLocalStorageItem("isPinLogin");
  }

  userName: string;
  getUserFullName() {
    if (this.userInfo) {
      this.userName = this.helperService.getFullName(this.userInfo);
    } else {
      this.userName = "Unknown";
    }
    this.layoutService.setUserFullName(this.userName);
  }

  toogleSideNav() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  }

  navigateToStaffDetail(): void {
    //route to new profile component
    // this.router.navigate(["staff/manage-staff/view", this.user_id]);
    this.router.navigate(["/profile"]);
  }
}
