import { Injectable } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { HttpClientService } from "@app/core/services/http-client/http-client.service";
import { GlobalService } from "@app/shared/services/global/global.service";
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  baseIp = environment.baseIp;
  apiPrefix = environment.apiPrefix;
  companyId = this.globalService.getCompanyIdFromStorage();
  newdate = new Date();
  currentDate = formatDate(this.newdate,"yyyy-MM-dd","en-US");
  constructor(
    private httpClientService: HttpClientService,
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) { }



  getAdminDashboardSummary(): Observable<any> {
    const params = new HttpParams().set("company_id", this.globalService.getCompanyIdFromStorage());

    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}site/admindashboard`,
      null,
      params
    );
  }

  getBirthday(): Observable<any> {
    const params = new HttpParams().set("company_id", this.globalService.getCompanyIdFromStorage());

    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}site/birthday`,
      null,
      params
    );
  }

 

  getIPDetails(): Observable<any> {
    const params = new HttpParams().set("company_id", this.globalService.getCompanyIdFromStorage());

    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}site/alertip`,
      null,
      params
    );
  }
  getStaffDashboardSummary() {
    const params = new HttpParams().set("company_id", this.companyId);

    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}site/staffdashboard`,
      null,
      params
    );
  }


  getAttendanceRank(){
   const params = new HttpParams().set("company_id", this.companyId);
    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}attendance/rank`,
      null,
      params
    );
  }

  getAttandance() {
    let params = {
      limit: 100,
      page: 1,
      sortnane: "checkin_datetime",
      sortno: 2,
      company_id: this.globalService.getCompanyIdFromStorage(),
      search: {
        user_id: "",
        checkin_datetime: this.currentDate,
        checkout_datetime: "",
        correction_status: "",
        checked_by: "",
        checkin_message: "",
        correction_request: "",
      },
    };
    return this.httpClientService.post(
      `${this.baseIp}${this.apiPrefix}attendance/search`,
      params,
      null
    );
  }

  getAttandanceCorrection() {
    let params = {
      limit: 100,
      page: 1,
      sortnane: "checkin_datetime",
      sortno: 2,
      company_id: this.globalService.getCompanyIdFromStorage(),
      search: {
        user_id: "",
        checkin_datetime: "",
        checkout_datetime: "",
        correction_status: "P",
        checked_by: "",
        checkin_message: "",
        correction_request: 1,
      },
    };
    return this.httpClientService.post(
      `${this.baseIp}${this.apiPrefix}attendance/search`,
      params,
      null
    );
  }
  
  getLeaveRequest() {
    let params = {
      company_id: this.globalService.getCompanyIdFromStorage(),
      limit: 10,
      page: "1",
      sortno: 2,
      sortnane: "date_to",
      search: {
        staff_id: "", date_to: "", date_from: "", description: "", leave_type: "", status: "0"
      }
    }
    return this.httpClientService.post(
      `${this.baseIp}${this.apiPrefix}leaves/search`,
      params,
      null
    );
  }

  updateAttendanceCorrection(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(`${this.baseIp}${this.apiPrefix}attendance/edit`, body, options);
  }


  addHolidays(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}holidays/add`,
      body,
      options
    );
  }

  // edit holiday method
  editHoliday(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}holidays/edit`,
      body,
      options
    );
  }


    // method to delet holiday
    deleteHolidayById(id): Observable<any> {
      const options = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      return this.httpClient.post(
        `${this.baseIp}${this.apiPrefix}holidays/remove`,
        id,
        options
      );
    }


}
